import { BrowserRouter as Router } from "react-router-dom";
import { ScrollToTop } from "./utlis/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContentWrapper from "./routes";

function App() {
  // main
  return (
    <div className="App w-full h-full relative overflow-x-hidden">
      <Router>
        <ScrollToTop />
        <ContentWrapper />
        <ToastContainer
          position="top-right"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
      </Router>
    </div>
  );
}

export default App;
