import React, { useState } from "react";
import { minus, plus } from "../svg";

type AccordionProps = {
  item: { question: string; answer: string; id: number }[];
};

const Accordion = ({ item }: AccordionProps) => {
  const [expandedIndex, setExpandedIndex] = useState<null | number>();

  const renderedItems = item.map((el, idx) => {
    const isExpanded = idx === expandedIndex;

    return (
      <div
        className={`${
          //   isExpanded ? "text-primary" : "text-secondary"
          ""
        }  flex flex-col  cursor-pointer w-full `}
        key={el.id}
      >
        <div
          className={`flex items-center justify-between py-6 ${
            !isExpanded && item.length !== el.id
              ? "border-t border-b-hgray-200"
              : " "
          }`}
          onClick={() => {
            isExpanded ? setExpandedIndex(null) : setExpandedIndex(idx);
          }}
        >
          <h3 className="font-ibm font-bold text-[15.5px] text-htext-main">
            {el.question}
          </h3>

          <div>
            <span
              className=""
              dangerouslySetInnerHTML={{
                __html: isExpanded ? minus : plus,
              }}
            />
          </div>
        </div>
        {isExpanded && (
          <p className="font-ibm font-normal text-[14.5px] text-htext-label w-full border-b border-b-hgray-200 pb-6">
            {el.answer}
          </p>
        )}
      </div>
    );
  });

  return <>{renderedItems}</>;
};

export default Accordion;
