export const differData = [
  {
    title: "Multichain Support",
    subtitle:
      "Access various blockchain networks with seamless integration for diverse transaction needs.",
    icon: "/multichain.svg",
  },
  {
    title: "Instant Conversions",
    subtitle:
      "Enjoy rapid crypto-to-fiat transfers, ensuring your funds are available whenever you need them.",
    icon: "/instant.svg",
  },
  {
    title: "Business Solutions",
    subtitle:
      "Tailored decentralized solutions designed to meet the specific needs of your enterprise.",
    icon: "/business.svg",
  },
  {
    title: "Global Coverage",
    subtitle:
      "Your reach with services available across multiple countries and regions.",
    icon: "/globe.svg",
  },
  {
    title: "Seamless Transactions",
    subtitle:
      "Experience frictionless transfers between stablecoins and fiat, ensuring your funds move effortlessly across different currencies.",
    icon: "/seamless.svg",
  },
  {
    title: "Enterprise-Grade Security",
    subtitle:
      "Rest assured with industry-leading encryption, secure smart contracts, and fraud prevention mechanisms protecting your assets.",
    icon: "/enterprise.svg",
  },
];
