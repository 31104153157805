import { FC, useState, useEffect, useRef } from "react";
import { arrowDownSVG, arrowRightSVG, arrowUpSVG, closeMobBtn } from "../svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);
  const [showProductsDrop, setShowProductsDrop] = useState(false);
  const [showMobProductsDrop, setShowMobProductsDrop] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const notHome = location.pathname !== "/";

  const dropdownRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);

  // Toggle the product dropdown when clicking the "Products" button
  const handleProductClick = () => {
    setShowProductsDrop((prev) => !prev); // Toggle dropdown visibility
  };

  const handleProductMobClick = () => {
    setShowMobProductsDrop((prev) => !prev); // Toggle dropdown visibility
  };

  // Close the dropdown if clicking outside the navbar or dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setShowProductsDrop(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      ref={navbarRef}
      className={`w-full fixed top-0 left-0 right-0 w-full py-5 z-50 ${
        isScrolled ? "bg-h-bg" : "bg-transparent"
      }`}
    >
      <div className="max-content">
        <div className="container">
          <div className={`relative flex items-center justify-between`}>
            <div
              className="nav-logo w-[100px] cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img
                className=""
                src={`${
                  notHome && !isScrolled
                    ? "/shigaLogoBlueSmall.svg"
                    : "/shigaLogo.svg"
                }`}
                alt="shiga logo"
              />
            </div>
            <div
              className={`navbar-menu mdxl:hidden block w-[480px] bg-white rounded-lg flex items-center justify-between pl-[16px] pr-2 py-[8px] text-[14.5px] text-htext-main font-roobert font-medium`}
            >
              <div
                className="flex items-center cursor-pointer hover:bg-black/10 px-2 py-[1.5px] rounded-[4px]"
                onClick={() => handleProductClick()}
              >
                <p className="cursor-pointer">Products</p>
                <span
                  className="ml-0.5 cursor-pointer"
                  dangerouslySetInnerHTML={{ __html: arrowDownSVG }}
                />
              </div>

              <Link
                className="cursor-pointer hover:bg-black/10 px-2 py-[1.5px] rounded-[4px]"
                to="/about-us"
              >
                About
              </Link>

              <div className="flex items-center gap-x-6">
                <a
                  className="cursor-pointer hover:bg-black/10 px-2 py-[1.5px] rounded-[4px]"
                  rel="noreferrer"
                  href="https://business.shiga.io/login"
                  // target="_blank"
                >
                  Sign in
                </a>
                <a href="https://business.shiga.io/signup">
                  <button className="nav-btn bg-hbrand-600 hover:bg-hbrand-700 text-white shadow-s-light rounded-lg px-[32px] py-1.5">
                    <p>Get started</p>
                  </button>
                </a>
              </div>
            </div>
            <div
              className=" mdxl:block hidden  p-[10px] flex justify-center items-center bg-white rounded-[7.302px] cursor-pointer shadow-s-light"
              onClick={() => setShowMobileMenu(true)}
            >
              <img
                src="/menuOpen.svg"
                alt="iconn"
                className="w-[22px] h-[18px]"
              />
            </div>
          </div>
        </div>
      </div>
      {showProductsDrop && (
        <div
          ref={dropdownRef}
          className="productDrop w-full absolute bg-white px-32 left-0 right-0 top-[5.6rem]"
        >
          <div className="productDrop-cont flex items-start w-full">
            <div className="cont-links flex items-start py-12 w-full gap-x-7">
              <div className="link1 w-[220px] p-3">
                <h6 className="font-ibm text-[15px] font-bold text-htext-main">
                  Instant Currency Swaps
                </h6>
                <p className="mt-2 font-ibm text-[15px] font-normal text-htext-subdued">
                  Easily convert stablecoins to local currencies and vice versa,
                  anytime you need.
                </p>
                <Link
                  to="/portal-in-and-out"
                  className="mt-3 flex items-center hover:text-hbrand-600"
                  onClick={() => handleProductClick()}
                >
                  <p className="font-ibm text-[13px] font-bold text-hbrand-700">
                    Learn more
                  </p>
                  <span
                    className="mt-0.5 ml-1.5"
                    dangerouslySetInnerHTML={{ __html: arrowRightSVG }}
                  />
                </Link>
              </div>

              <div className="link2 w-[220px] p-3">
                <h6 className="font-ibm text-[15px] font-bold text-htext-main">
                  High-Volume Trades Made Simple
                </h6>
                <p className="mt-2 font-ibm text-[15px] font-normal text-htext-subdued">
                  Secure, large-scale trades with personalized support and
                  competitive rates.
                </p>
                <Link
                  to="/shiga-otc-tailored-for-high-volume-trades"
                  className="mt-3 flex items-center hover:text-hbrand-600"
                  onClick={() => handleProductClick()}
                >
                  <p className="font-ibm text-[13px] font-bold text-hbrand-700">
                    Learn more
                  </p>
                  <span
                    className="mt-0.5 ml-1.5"
                    dangerouslySetInnerHTML={{ __html: arrowRightSVG }}
                  />
                </Link>
              </div>

              <div className="link3 w-[220px] p-3">
                <h6 className="font-ibm text-[15px] font-bold text-htext-main">
                  Tailored Blockchain Solutions
                </h6>
                <p className="mt-2 font-ibm text-[15px] font-normal text-htext-subdued">
                  Custom decentralized applications designed for your business
                  needs.
                </p>
                <Link
                  to="/blockchain-finance-for-global-enterprises"
                  className="mt-3 flex items-center hover:text-hbrand-600"
                  onClick={() => handleProductClick()}
                >
                  <p className="font-ibm text-[13px] font-bold text-hbrand-700">
                    Learn more
                  </p>
                  <span
                    className="mt-0.5 ml-1.5"
                    dangerouslySetInnerHTML={{ __html: arrowRightSVG }}
                  />
                </Link>
              </div>
            </div>

            <div className="bg-[#F9FAFB] px-7 py-8 w-[510px] h-full relative flex flex-col items-center justify-center mx-auto border-b border-b-hgray-200 shadow-nav-thick">
              <h6 className="font-ibm px-2.5 text-[13px] font-bold text-htext-main text-start w-full">
                Get started
              </h6>

              <img
                className="mt-3"
                src="/assets/navDropImg.svg"
                alt="Shiga Web App"
              />

              <a
                href="https://business.shiga.io/signup"
                // target="_blank"
                className="mt-7 px-2.5 flex items-center justify-start w-full hover:text-hbrand-600"
                onClick={() => handleProductClick()}
              >
                <p className="font-ibm text-[13px] font-bold text-hbrand-700">
                  Create an account
                </p>
                <span
                  className="mt-0.5 ml-1.5"
                  dangerouslySetInnerHTML={{ __html: arrowRightSVG }}
                />
              </a>
            </div>
          </div>
        </div>
      )}
      {showMobileMenu && (
        <div className="w-full max-h-[48rem] h-auto min-h-[40rem] bg-white flex flex-col items-start justify-between absolute top-0 left-0 py-5">
          <div className="w-full flex flex-col items-start justify-start">
            <div className="nav_top flex items-center justify-between w-full pb-7 px-6">
              <div className="nav_logo w-[105px]">
                <img
                  className=""
                  src="/shigaLogoBlueSmall.svg"
                  alt="shiga logo"
                />
              </div>

              <div>
                <button
                  type="button"
                  className="hover:shadow-s-light"
                  onClick={() => {
                    setShowMobileMenu(false);
                    setShowMobProductsDrop(false);
                  }}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: closeMobBtn }}
                  />
                </button>
              </div>
            </div>

            <div className="nav_middle w-full flex flex-col items-start justify-center px-5 text-htext-main font-bold font-ibm text-[16.5px]">
              <div className="w-full py-5">
                <div
                  className="middle-prod w-full flex items-center justify-between"
                  onClick={() => handleProductMobClick()}
                >
                  <p
                    className={`cursor-pointer ${
                      showMobProductsDrop ? "text-hbrand-600" : ""
                    }`}
                  >
                    Products
                  </p>
                  <span
                    className="cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: showMobProductsDrop ? arrowUpSVG : arrowDownSVG,
                    }}
                  />
                </div>

                {showMobProductsDrop && (
                  <div className="mobDropMenu px-3">
                    <div className="pt-6 pb-3 hover:text-hbrand-500">
                      <Link
                        to="/portal-in-and-out"
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <h6 className="font-semibold text-[15px]">
                          Instant Currency Swaps
                        </h6>
                        <p className="text-htext-subdued text-[15px] font-normal mt-2">
                          Easily convert stablecoins to local currencies and
                          vice versa, anytime you need.
                        </p>
                      </Link>
                    </div>

                    <div className="py-3 hover:text-hbrand-500">
                      <Link
                        to="/shiga-otc-tailored-for-high-volume-trades"
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <h6 className="font-semibold text-[15px]">
                          High-Volume Trades Made Simple
                        </h6>
                        <p className="text-htext-subdued text-[15px] font-normal mt-2">
                          Secure, large-scale trades with personalized support
                          and competitive rates.
                        </p>
                      </Link>
                    </div>

                    <div className="pt-3 pb-1 hover:text-hbrand-500">
                      <Link
                        to="/blockchain-finance-for-global-enterprises"
                        onClick={() => setShowMobileMenu(false)}
                      >
                        <h6 className="font-semibold text-[15px]">
                          Tailored Blockchain Solutions
                        </h6>
                        <p className="text-htext-subdued text-[15px] font-normal mt-2">
                          Custom decentralized applications designed for your
                          business needs.
                        </p>
                      </Link>
                    </div>
                  </div>
                )}
              </div>

              <Link
                className="cursor-pointer w-full"
                to="/about-us"
                onClick={() => setShowMobileMenu(false)}
              >
                <div className="middle-prod w-full border-t border-t-hgray-200 flex items-center justify-between py-5">
                  <p>About</p>
                </div>
              </Link>
            </div>
          </div>

          <div className="w-full flex flex-col items-start justify-center px-5 text-htext-main font-bold font-ibm text-[16.5px]">
            <div className="w-full">
              <a href="https://business.shiga.io/signup">
                <button className="text-center w-full bg-hbrand-600 text-white text-[15px] font-ibm font-semibold py-3 rounded-lg shadow-s-light border border-hbrand-600">
                  Get started
                </button>
              </a>
            </div>
            <div className="w-full">
              <a href="https://business.shiga.io/login">
                <button className="text-center w-full bg-white text-htext-main text-[15px] font-ibm font-semibold py-3 rounded-lg shadow-s-light border border-white">
                  Sign in
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
