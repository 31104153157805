import { FC } from "react";
import { mail } from "../../common/svg";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useMobile } from "../../utlis";

// Contact data interface
interface ContactData {
  name: string;
  email: string;
  message: string;
}

// API response interface
interface ApiResponse {
  success: boolean;
  message: string;
}

const postContactUs = async (
  contactData: ContactData
): Promise<ApiResponse> => {
  const response = await fetch(
    "https://americano.shiga.io/api/v1/webContactUs",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contactData),
    }
  );

  if (!response.ok) {
    const errorData = await response.json(); // Parse the response body before logging
    const errorMessage = errorData?.message || "Failed to send message";

    throw new Error(errorMessage);
  }

  return response.json();
};

const Contact: FC = () => {
  const isMobile = useMobile();

  const { register, handleSubmit, reset } = useForm<ContactData>();

  const mutation = useMutation<ApiResponse, Error, ContactData>({
    mutationFn: postContactUs, // mutation function goes here
    onSuccess: () => {
      toast("Message was sent successfully");
      reset(); // Reset the form after successful submission
    },
    onError: (error: Error) => {
      console.log("Error:", error);
      toast.error(`Error: ${error.message}`);
    },
  });

  const onSubmit = (data: ContactData) => {
    mutation.mutate(data); // Trigger the mutation with form data
  };

  return (
    <div className="">
      <div
        className={` relative flex flex-col items-center justify-center bg-hbrand-25 pt-[120px] pb-8 z-10 w-full ${
          isMobile ? "h-[638px]" : "h-[415px]"
        } `}
      >
        <div className="max-content">
          <div className="container">
            <div
              className={`w-full  lg:flex-col flex relative items-start justify-between`}
            >
              <div
                className={`flex flex-col items-start justify-center ${
                  isMobile ? "w-full" : "w-[48%]"
                }`}
              >
                <h1
                  className={`text-htext-main ${
                    isMobile
                      ? "text-[31px] leading-[38px]"
                      : "text-[44px] leading-[58px]"
                  } font-satoshi font-black`}
                >
                  Let’s get you set up
                </h1>
                <p
                  className={`text-htext-label ${
                    isMobile ? "text-[18px]" : "text-[17.5px]"
                  } font-inter font-normal mt-[22px] text-start`}
                >
                  Fill the form to connect with our team right away. We’d love
                  to hear from you to understand your needs with using the
                  blockchain to your financial advantage.
                </p>
              </div>

              {isMobile && (
                <div className="backed-bottom py-8 w-full">
                  <h5 className="text-htext-label text-[15px] font-satoshi font-black">
                    Are you an existing customer?
                  </h5>
                  <p className="text-htext-label text-[17px] font-ibm font-normal mt-6">
                    Log in to your account and use the{" "}
                    <a
                      href="https://business.shiga.io/login"
                      className="text-hbrand-600 hover:text-hbrand-700"
                    >
                      Help & FAQ
                    </a>{" "}
                    section for quick answers. It’s just one of the many
                    benefits of being our customer.
                  </p>
                </div>
              )}

              <form
                className={`${
                  isMobile
                    ? "w-[90%] right-0 left-0 mx-auto mt-[20px] top-[24rem]"
                    : "w-[35%] lg:w-[45%] right-[38px]"
                } absolute bg-white pt-[34px] lg:-mt-[32px] pb-[28px] px-[35px] border border-hgray-200 rounded-[24px] shadow-s-light`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <fieldset>
                  <p className="text-htext-main text-[17.5px] font-ibm font-semibold text-start">
                    Send us a message
                  </p>
                  <div className="form-conts mt-5">
                    <label htmlFor="name">
                      <div className="mb-1.5 flex items-center text-[12.5px] font-ibm font-medium">
                        <p className="mr-1 -mt-[2px] h-[10px] text-herror-600">
                          *
                        </p>
                        <p className="text-htext-label">Name</p>
                      </div>
                      <div className="input-cont w-full px-[12px] font-ibm border border-hgray-300 rounded-lg shadow-s-light">
                        <input
                          type="text"
                          className="w-full py-[9.6px] text-[12.6px] focus:outline-none border-0 rounded-lg bg-transparent"
                          placeholder=""
                          {...register("name", { required: true })}
                        />
                      </div>
                    </label>

                    <label htmlFor="email">
                      <div className="mb-1.5 mt-4 flex items-center text-[12.5px] font-ibm font-medium">
                        <p className="mr-1 -mt-[2px] h-[10px] text-herror-600">
                          *
                        </p>
                        <p className="text-htext-label">Email</p>
                      </div>
                      <div className="input-cont w-full flex items-center font-ibm pl-[8px] pr-[12px] border border-hgray-300 rounded-lg shadow-s-light">
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: mail }}
                        />

                        <input
                          type="text"
                          className="w-full ml-2 py-[9.6px] text-[12.6px] focus:outline-none focus:bg-transparent border-0 rounded-lg bg-transparent"
                          placeholder=""
                          {...register("email", { required: true })}
                        />
                      </div>
                    </label>

                    <label htmlFor="message">
                      <div className="mb-1.5 mt-5 flex items-center text-[12.5px] font-ibm font-medium">
                        <p className="text-htext-label">Your message</p>
                      </div>
                      <div className="input-cont w-full px-[8px] font-ibm border border-hgray-300 rounded-lg shadow-s-light">
                        <textarea
                          className="w-full py-[6.6px] rounded-md text-[12.6px] outline-none border-0 bg-transparent"
                          maxLength={1200}
                          rows={6}
                          style={{ resize: "none" }}
                          {...register("message", { required: true })}
                        />
                      </div>
                    </label>

                    {mutation.isError && (
                      <p className="mt-2 text-herror-600 text-[12.8px] font-ibm font-medium">
                        {mutation.error.message}
                      </p>
                    )}
                  </div>

                  <div className="form-btns mt-10">
                    <button
                      type="submit"
                      disabled={mutation.status === "pending"}
                      className="w-full py-[0.9rem] rounded-lg border border-hbrand-600 bg-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 text-center text-white text-[15.5px] font-ibm font-semibold shadow-s-light"
                    >
                      {mutation.status === "pending"
                        ? "Sending..."
                        : "Send message"}
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="max-content">
          <div className="container">
            <div
              className={`contact-texts relative  flex flex-col items-start justify-center w-full ${
                isMobile
                  ? "h-[1030px] pb-[0px] pt-[160px]"
                  : "h-full  pb-[200px] pt-[48px]"
              }`}
            >
              <div
                className={`${
                  isMobile ? "w-full" : "w-[42%]"
                } backed-top pb-12`}
              >
                <h5
                  className={`text-htext-label ${
                    isMobile ? "text-[15px]" : "text-[14.5px]"
                  } font-satoshi font-black`}
                >
                  Backed by the best
                </h5>
                <p
                  className={`text-htext-label ${
                    isMobile ? "text-[17px] mt-6" : "text-[16px] mt-7"
                  } font-ibm font-normal`}
                >
                  Shiga partners with licensed financial institutions globally
                  and offers decentralized solutions that ensure security and
                  trust.
                </p>
                <div className="flex mt-9 xlsm:flex-wrap items-center gap-[47px]">
                  <img
                    className={`w-[95px] mt-[16px]`}
                    src="/assets/dfs.svg"
                    alt="DFS Labs"
                  />
                  <img
                    className={` w-[95px] mt-[16px]`}
                    src="/assets/stellar.svg"
                    alt="Stellar"
                  />
                </div>
              </div>
              {!isMobile && (
                <div className="backed-bottom border-t border-t-hgray-200 py-12 w-[42%]">
                  <h5 className="text-htext-label text-[14.5px] font-satoshi font-black">
                    Are you an existing customer?
                  </h5>
                  <p className="text-htext-label text-[16px] font-ibm font-normal mt-6">
                    Log in to your account and use the{" "}
                    <a
                      href="https://business.shiga.io/login"
                      className="text-hbrand-600 hover:text-hbrand-700"
                    >
                      Help & FAQ
                    </a>{" "}
                    section for quick answers. It’s just one of the many
                    benefits of being our customer.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
