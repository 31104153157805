import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../components/home";
import Business from "../components/business";
import Portal from "../components/portal";
import Payment from "../components/payment";
import CaseStudyHome from "../components/case-studies";
import About from "../components/about";
import Contact from "../components/contact";
import CaseStudyDoBusiness from "../components/case-studies/do-business";
import CaseStudyHelpOthers from "../components/case-studies/help-others";
import CaseStudyEnableBusiness from "../components/case-studies/enables-business";
import ComingSoon from "../components/coming-soon";
import NotFound from "../components/notFound";
import MainLayout from "../layout";
import Navbar from "../common/navbar";
import PrivacyPolicy from "../components/privacyPolicy";
import TermsOfService from "../components/termsOfService";

const ContentWrapper: FC = () => {
  return (
    <div className="app-container relative overflow-x-hidden hide-scrollbar flex flex-col min-h-screen w-full h-full items-center justify-between">
      <section className="">
        <Navbar />
      </section>

      <Routes>
        {/* Routes for different components */}
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          {/* <Route path="/blog/:blogId" element={<BlogDetails />} /> */}
          <Route path="/about-us" element={<About />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/contact-support" element={<Contact />} />
          <Route path="/portal-in-and-out" element={<Portal />} />
          <Route path="/our-case-studies" element={<CaseStudyHome />} />
          <Route
            path="/shiga-otc-tailored-for-high-volume-trades"
            element={<Payment />}
          />
          <Route
            path="/blockchain-finance-for-global-enterprises"
            element={<Business />}
          />
          <Route
            path="/case-study/do-business-in-africa-without-an-african-bank-account"
            element={<CaseStudyDoBusiness />}
          />
          <Route
            path="/case-study/protect-your-wealth-from-local-currency-devaluation-by-using-stablecoins"
            element={<CaseStudyHelpOthers />}
          />
          <Route
            path="/case-study/accept-crypto-as-a-payment-method-without-knowing-anything-about-crypto"
            element={<CaseStudyEnableBusiness />}
          />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>

        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default ContentWrapper;
