import { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import debounce from "lodash.debounce";

export const ScrollToTop: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [pathname]); // Triggers when the pathname changes

  return null;
};

export const useFourK = (): boolean => {
  const [isFourK, setIsFourK] = useState(false);

  useEffect(() => {
    const checkResolution = () => {
      setIsFourK(window.innerWidth >= 3840 && window.innerHeight >= 2160);
    };

    checkResolution(); // Run on initial render
    window.addEventListener("resize", checkResolution);

    return () => {
      window.removeEventListener("resize", checkResolution);
    };
  }, []);

  return isFourK;
};

//TODO remove hook
export const useMobile = (breakpoint = 968, delay = 200): boolean => {
  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== "undefined") {
      return window.innerWidth <= breakpoint;
    }
    return false; // Default to non-mobile during SSR
  });

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth <= breakpoint);
    }, delay);

    window.addEventListener("resize", handleResize);

    // Cleanup event listener and debounce instance
    return () => {
      handleResize.cancel(); // Cancel any pending debounced calls
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint, delay]);

  return isMobile;
};
