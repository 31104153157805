import { FC, useRef } from "react";
import {
  coin1,
  coin2,
  coin3,
  coin4,
  coin5,
  coin6,
  coin7,
  coin8,
  coin9,
  coin10,
  topDrop,
  step1,
  step2,
  step3,
  shield,
  coverage,
  bolt,
  multichain,
  topDropMob,
  stepMob1,
  stepMob2,
  stepMob3,
} from "../../common/svg";
import { Link } from "react-router-dom";
import { useMobile } from "../../utlis";
import Accordion from "../../common/accordion/Accordion";
import { Slide } from "react-awesome-reveal";
import { faqContent1, faqContent2 } from "../../constants/FAQ";

const InfiniteScroll = ({ coins }: { coins: string[] }) => {
  const coinContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={coinContainerRef}
      className="bottom-coins w-[95%] h-auto absolute left-0 right-0 m-auto -bottom-[30px] shadow-s-thicker rounded-2xl bg-white py-5 px-4 z-20 flex items-center gap-x-3.5 overflow-x-hidden cursor-pointer infinite-scroll"
    >
      {/* Clone the content multiple times for continuous scrolling */}
      <div className="infinite-scroll-content">
        {[...coins, ...coins].map((coin, index) => (
          <span
            key={index}
            className="hover:scale-150"
            dangerouslySetInnerHTML={{ __html: coin }}
          />
        ))}
      </div>
    </div>
  );
};

const Portal: FC = () => {
  const isMobile = useMobile();

  const coinArray = [
    coin1,
    coin2,
    coin3,
    coin4,
    coin5,
    coin6,
    coin7,
    coin8,
    coin9,
    coin10,
  ];

  return (
    <div className="relative">
      <section className=" w-full h-full bg-white">
        <div className="max-content">
          <div className="container">
            <div
              className={`relative flex justify-between  pb-8 z-10 w-full ${
                isMobile
                  ? "pt-[7.5rem] h-[578px] items-center"
                  : "pt-12 h-[620px] items-start"
              } `}
            >
              <div
                className={` ${
                  isMobile
                    ? " w-full -mt-[0.5rem] items-center"
                    : " lg:w-[500px] w-[694px] mt-[9rem]"
                }  flex flex-col justify-center z-20`}
              >
                <p
                  className={`font-ibm font-medium sm:text-[11px] text-[10.5px]   px-2 py-[0.5px] rounded-2xl text-hsuccess-700 bg-hsuccess-50 border border-hsuccess-200 flex w-fit `}
                >
                  PORTAL IN/OUT
                </p>
                <h1
                  className={`text-htext-main ${
                    isMobile
                      ? "text-[31px] leading-[38px] text-center"
                      : "text-[43px] leading-[58px]"
                  } font-satoshi font-black mt-[24px]`}
                >
                  Your Wallet, Now with Bank Account Features
                </h1>
                <p
                  className={`text-htext-label ${
                    isMobile ? "text-[19px] text-center" : "text-[17.5px]"
                  } font-inter font-normal mt-[24px]`}
                >
                  Move your money seamlessly between stablecoins and fiat.
                  Whether you're sending, receiving, or swapping, we've got you
                  covered.
                </p>
                <div
                  className={`mt-[40px] flex items-center ${
                    isMobile ? "flex-col w-full" : ""
                  }`}
                >
                  <a href="https://business.shiga.io/signup">
                    <button
                      className={`${
                        isMobile ? "px-[1.1rem]" : "w-[275px] lg:[150px]"
                      } py-[9.5px] text-[14.5px] text-white font-ibm font-semibold rounded-[7px] border border-hbrand-600 bg-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 shadow-s-light`}
                    >
                      Get Started with Portal In/Out
                    </button>
                  </a>
                  <Link to="/case-study/protect-your-wealth-from-local-currency-devaluation-by-using-stablecoins">
                    <p
                      className={`text-hbrand-700 text-[14px] font-ibm font-bold ${
                        isMobile
                          ? "text-center mt-[2.3rem]"
                          : "ml-[38px] text-nowrap"
                      }`}
                    >
                      View case study
                    </p>
                  </Link>
                </div>
              </div>

              <div
                className={`${
                  isMobile ? "hidden" : ""
                } w-[490px] lg:w-[400px] relative  mt-[7rem]  flex flex-col justify-center`}
              >
                <img
                  className="-mt-1 w-[93%] relative z-20 m-auto"
                  src="/assets/calcB.svg"
                  alt="Rates Calculator"
                />
                <InfiniteScroll coins={coinArray} />
              </div>
            </div>
          </div>
        </div>

        <div className=" relative bg-white">
          <div className="max-content">
            <div className="container">
              <div
                className={`solu-top pt-[33px] pb-[24px] flex flex-col items-center text-center`}
              >
                <h2
                  className={`font-satoshi font-black text-hgray-950 ${
                    isMobile ? "text-[23px] leading-8" : "text-[30.5px]"
                  }`}
                >
                  Explore how effortless swapping works
                </h2>
                <span
                  className="arrow-pointer relative mt-[20.5px] cursor-pointer hover:opacity-80"
                  dangerouslySetInnerHTML={{
                    __html: isMobile ? topDropMob : topDrop,
                  }}
                  onClick={() => {
                    window.scrollBy({
                      top: 800, // Scrolls 150px downward
                      behavior: "smooth", // Smooth scroll effect
                    });
                  }}
                />
              </div>

              <div
                className={` relative lg:flex-col py-[88px] flex items-center justify-between`}
              >
                <div
                  className={`bottom-right lg:w-full w-[43%] pl-5 relative minlg:mb-16 ${
                    isMobile ? "" : ""
                  } flex flex-col justify-center`}
                >
                  <h3
                    className={`mt-3.5 font-satoshi font-bold ${
                      isMobile
                        ? "text-[29.5px] leading-[38px]"
                        : "text-[36.5px] leading-[48px]"
                    } text-hgray-950`}
                  >
                    Use your wallet like a bank account
                  </h3>
                  <p
                    className={`mt-6 font-ibm font-normal text-htext-label ${
                      isMobile ? "w-full text-[16.5px]" : " text-[17.5px]"
                    }`}
                  >
                    Securely deposit stablecoins (like USDT or USDC) and easily
                    use them for everyday needs like paying bills, sending
                    money, or shopping – all while moving funds on and off the
                    blockchain.
                  </p>
                </div>

                <div
                  className={`bottom-left bottom-wallet relative ${
                    isMobile ? "w-full h-[300px] mt-32" : ""
                  } flex flex-col justify-center w-[51%]  h-[490px] mdxl:h-[300px]`}
                >
                  <img
                    src="/busiPattern.svg"
                    alt=""
                    className="w-full h-full object-cover absolute top-0 h-full w-full rounded-md"
                  />
                  <Slide direction="right">
                    <img
                      className={`${
                        isMobile ? "w-full" : "w-full mdxl:h-[300px]"
                      } relative z-20 absolute top-0 m-auto object-cover top-6 hover:scale-105`}
                      src="/assets/yourWallet.svg"
                      alt=""
                    />
                  </Slide>
                </div>
              </div>

              <div
                className={`liquidity-bottom lg:flex-col-reverse mdxl:mt-[50px] xlsm:mt-[30px] relative pb-[88px] ${
                  isMobile ? "pt-[8px] " : "pt-[11px]"
                } flex items-center justify-between`}
              >
                <div
                  className={`bottom-left bottom-current   relative ${
                    isMobile ? "w-full h-[300px] mt-8" : "w-[52%] h-[490px] "
                  } flex flex-col justify-center`}
                >
                  <Slide direction="left">
                    <img
                      className={`${
                        isMobile ? "w-[55%]" : "w-[45%]"
                      } relative z-20 m-auto hover:scale-105 object-cover`}
                      src="/assets/swaps.svg"
                      alt=""
                    />
                  </Slide>
                </div>

                <div
                  className={`bottom-right lg:w-full w-[43%]  relative minlg:mb-20 flex flex-col justify-center`}
                >
                  <h3
                    className={`mt-3.5 font-satoshi font-bold ${
                      isMobile
                        ? "text-[29.5px] leading-[38px]"
                        : "text-[37.5px] leading-[48px]"
                    } text-hgray-950`}
                  >
                    Swap Stablecoins for Local Currency – Better Than Your Bank
                  </h3>
                  <p
                    className={`mt-6 font-ibm font-normal text-htext-label ${
                      isMobile
                        ? "w-full text-[16.5px]"
                        : "w-[430px] text-[17.5px]"
                    }`}
                  >
                    Quickly exchange stablecoins for currencies like GBP, Naira,
                    or USD. Plus, enjoy your own IBAN, handling multiple
                    currencies, free exchanges, and instant transfers – all with
                    better benefits than your bank.
                  </p>
                </div>
              </div>

              <div
                className={`liquidity-bottom lg:flex-col lg:items-start relative pb-[60px] ${
                  isMobile ? "pt-[8px] " : "pt-[11px]"
                } flex items-center justify-between`}
              >
                <div
                  className={`bottom-right w-[42%] lg:w-full  relative flex flex-col justify-center`}
                >
                  <h3
                    className={`mt-3.5 font-satoshi font-bold ${
                      isMobile
                        ? "text-[29.5px] leading-[38px]"
                        : "text-[37.5px] leading-[48px]"
                    } text-hgray-950`}
                  >
                    Withdraw or spend
                  </h3>
                  <p
                    className={`mt-6 font-ibm font-normal text-htext-label ${
                      isMobile
                        ? "w-full text-[16.5px]"
                        : "w-[430px] text-[17.5px]"
                    }`}
                  >
                    In cash or in crypto, it's up to you! We'll seamlessly
                    handle the exchange of crypto to EUR, NGN, USD, ZAR, and 20+
                    other currencies based on your needs.
                  </p>

                  <div
                    className={`flex ${
                      isMobile ? "flex-col items-start" : "items-center"
                    } justify-start mt-[10px] gap-x-2`}
                  >
                    <p
                      className={`font-ibm font-normal ${
                        isMobile ? "text-[13px] mt-3.5" : "text-[12px]"
                      } text-htext-label`}
                    >
                      SUPPORTS
                    </p>
                    <div
                      className={`flex items-center ${
                        isMobile ? "mt-1.5 gap-x-6" : "gap-x-2"
                      }`}
                    >
                      <img
                        className={`${isMobile ? "w-[70px]" : "w-[55px]"}`}
                        src="/assets/faster.svg"
                        alt="faster payment"
                      />
                      <img
                        className={`${isMobile ? "w-[70px]" : "w-[55px]"}`}
                        src="/assets/sepa.svg"
                        alt="sepa payment"
                      />
                    </div>

                    <div
                      className={`flex items-center ${
                        isMobile ? "mt-1.5 gap-x-6" : "gap-x-2"
                      }`}
                    >
                      <img
                        className={`${isMobile ? "w-[70px]" : "w-[55px]"}`}
                        src="/assets/fedwire.svg"
                        alt="fedwire payment"
                      />
                      <img
                        className={`${isMobile ? "w-[70px]" : "w-[55px]"}`}
                        src="/assets/ach.svg"
                        alt="ach payment"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`bottom-left bottom-hands relative ${
                    isMobile
                      ? "w-full h-[300px] mt-8"
                      : "w-[52%] lg:w-[40%] h-[490px] "
                  } flex flex-col justify-center`}
                >
                  <Slide direction="right">
                    <img
                      className={` w-[55%] mdxl:h-[300px]${
                        isMobile ? "w-[62%]" : ""
                      } relative z-20 m-auto top-1 hover:scale-105 object-contain"`}
                      src="/assets/handShakes.svg"
                      alt=""
                    />
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" w-full h-full bg-h-bg">
        <section className="max-content">
          <section className="container">
            <div className="">
              <div
                className={`built-cont ${
                  isMobile ? "py-[70px]" : "py-[104px]"
                }`}
              >
                <h2
                  className={`differ-header font-satoshi font-black text-white text-[43.5px] text-center ${
                    isMobile ? "leading-[38px]" : ""
                  }`}
                >
                  Why Choose Shiga’s Portal In/Out?
                </h2>

                <div
                  className={`flex flex-col items-center justify-center ${
                    isMobile ? "mt-[60px]" : "mt-[70px]"
                  }`}
                >
                  <div
                    className={`flex w-full ${
                      isMobile
                        ? "items-center flex-wrap gap-y-12"
                        : "items-start gap-x-[64px]"
                    } justify-center`}
                  >
                    <div
                      className={`col-cont ${
                        isMobile
                          ? "w-full flex flex-col items-center"
                          : "w-[43.5%]"
                      }`}
                    >
                      <div className="flex items-center">
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: multichain }}
                        />
                        <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                          No hidden fees
                        </p>
                      </div>

                      <p
                        className={`built-texts mt-5 text-[16.5px] font-ibm font-normal text-hgray-300 mdxl:text-center`}
                      >
                        What you see is what you get—transparent pricing with no
                        surprises.
                      </p>
                    </div>

                    <div
                      className={`col-cont ${
                        isMobile
                          ? "w-full flex flex-col items-center"
                          : "w-[43.5%]"
                      }`}
                    >
                      <div className="flex items-center">
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: bolt }}
                        />
                        <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                          Quick swaps
                        </p>
                      </div>

                      <p
                        className={`built-texts mt-5 text-[16px] font-ibm font-normal text-hgray-300 ${
                          isMobile ? "text-center" : ""
                        }`}
                      >
                        Instantly swap stablecoins and fiat in minutes.
                      </p>
                    </div>
                  </div>

                  <div
                    className={`flex w-full ${
                      isMobile
                        ? "items-center flex-wrap gap-y-12 mt-12"
                        : "items-start gap-x-[64px] mt-[64px]"
                    } justify-center`}
                  >
                    <div
                      className={`col-cont ${
                        isMobile
                          ? "w-full flex flex-col items-center"
                          : "w-[43.5%]"
                      }`}
                    >
                      <div className="flex items-center">
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: shield }}
                        />
                        <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                          Secure transactions
                        </p>
                      </div>

                      <p
                        className={`built-texts mt-5 text-[16.5px] font-ibm font-normal text-hgray-300 ${
                          isMobile ? "text-center" : ""
                        }`}
                      >
                        Every transaction is protected with top-tier security
                        and full compliance.
                      </p>
                    </div>

                    <div
                      className={`col-cont ${
                        isMobile
                          ? "w-full flex flex-col items-center"
                          : "w-[43.5%]"
                      }`}
                    >
                      <div className="flex items-center">
                        <span
                          className=""
                          dangerouslySetInnerHTML={{ __html: coverage }}
                        />
                        <p className="ml-2 text-[16.5px] font-satoshi font-black text-white">
                          Global Coverage
                        </p>
                      </div>

                      <p
                        className={`built-texts mt-5 text-[16.5px] font-ibm font-normal text-hgray-300 ${
                          isMobile ? "text-center" : ""
                        }`}
                      >
                        Access a wide range of currencies across different
                        countries and regions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>

      <section className=" w-full h-full bg-hbrand-25">
        <div className="max-content">
          <div className="container">
            <div
              className={`w-full flex flex-col items-center py-[88px] mdxl:pt-[55px] mdxl:pb-[60px] `}
            >
              <h2
                className={`font-satoshi font-black text-hgray-950 ${
                  isMobile ? "text-[31px] leading-[38px]" : "text-[43.5px]"
                } text-center`}
              >
                Get started in 3 simple steps
              </h2>

              <div
                className={`flex items-start justify-center  w-full mt-[49px] gap-x-6 mdxl:flex-col mdxl:gap-y-12 `}
              >
                <div
                  className={`flex flex-col items-center justify-center text-center gap-y-3.5 ${
                    isMobile ? "w-full" : "w-[340px]"
                  }`}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: isMobile ? stepMob1 : step1,
                    }}
                  />
                  <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                    Create an account
                  </h6>
                  <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                    Sign up with your email and start your journey with Shiga.
                  </p>
                </div>

                <div
                  className={`flex flex-col items-center justify-center text-center gap-y-3.5 ${
                    isMobile ? "w-full" : "w-[340px]"
                  }`}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: isMobile ? stepMob2 : step2,
                    }}
                  />
                  <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                    Verify your account
                  </h6>
                  <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                    Complete a quick KYC process to unlock full functionality.
                  </p>
                </div>

                <div
                  className={`flex flex-col items-center justify-center  text-center gap-y-3.5 ${
                    isMobile ? "w-full" : "w-[340px]"
                  }`}
                >
                  <span
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: isMobile ? stepMob3 : step3,
                    }}
                  />
                  <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                    Start Using Shiga
                  </h6>
                  <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                    Send your stablecoins, make your swaps, and enjoy easy
                    withdrawals.
                  </p>
                </div>
              </div>
              <a href="https://business.shiga.io/signup">
                <button className="px-[70px] py-[10.5px] mt-[48px] border rounded-lg bg-hbrand-600 border-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 text-white text-[14.5px] font-ibm font-semibold shadow-s-light">
                  Create your account
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className=" w-full h-full pt-[120px] pb-[250px] mdxl:pt-[60px] mdxl:pb-[400px] bg-white">
        <div className="max-content">
          <div className="container">
            <div className={``}>
              <h2 className="faq-header font-satoshi font-black text-hgray-950 text-[43.5px] text-start">
                FAQ
              </h2>
              <div
                className={`w-full flex items-start justify-between flex-wrap mt-6 mdxl:flex-col`}
              >
                {/* First Column of FAQs */}
                <div
                  className={`flex flex-col items-start justify-center w-[48%] mdxl:w-full `}
                >
                  <Accordion item={faqContent1} />
                </div>

                {/* Second Column of FAQs */}
                <div
                  className={`flex flex-col items-start justify-center w-[48%] mdxl:w-full`}
                >
                  <Accordion item={faqContent2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portal;
